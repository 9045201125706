import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

const containerStyle = "border border-site-red p-6"
const iconContainer = "h-20 mb-12"
const h2Style = "text-white text-2xl md:text-3xl mb-6 uppercase font-semibold"
const pStyle = "text-white text-lg md:text-xl"

const SectionFourPartTest = () => {
  const data = useStaticQuery(graphql`
    query PageFourPartQuery {
      prismicFourPartTestPage {
        _previewable
        data {
          test_page_title
          test_page_short_description
          page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          section_title
          section_text
          section_icon {
            gatsbyImageData(
              width: 200
            )
          }
          first_card_title
          first_card_text
          first_card_icon {
            gatsbyImageData(
              width: 80
            )
          }
          second_card_title
          second_card_text
          second_card_icon {
            gatsbyImageData(
              width: 80
            )
          }
          third_card_title
          third_card_text
          third_card_icon {
            gatsbyImageData(
              width: 80
            )
          }
          fourth_card_title
          fourth_card_text
          fourth_card_icon {
            gatsbyImageData(
              width: 80
            )
          }
        }
      }
      imgHero: file(relativePath: { eq: "backgrounds/bg-mountain-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicFourPartTestPage
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)
  const iconSection = getImage(doc.data.section_icon)
  const iconOne = getImage(doc.data.first_card_icon)
  const iconTwo = getImage(doc.data.second_card_icon)
  const iconThree = getImage(doc.data.third_card_icon)
  const iconFour = getImage(doc.data.fourth_card_icon)

  return (
    <div className="py-20">
      <div className="bg-site-gray-darker w-11/12 lg:w-10/12 max-w-screen-lg mx-auto p-6 md:p-20 shadow-lg">
        <div>
          <div className="flex flex-col items-center">
            <div className="w-28 flex-shrink-0 mb-6">
              <GatsbyImage
                image={iconSection}
                alt="icon"
              />
            </div>
            <h2 className="text-3xl md:text-5xl text-white tracking-widest text-center">Compliance and the 4-Part Test</h2>
          </div>
          <p className="text-white text-xl md:text-2xl mt-6 text-center">An 831(b) plan can face scrutiny if IRS rules aren’t strictly adhered to. A qualified plan administrator helps ensure compliance through what’s known as the 4-Part Test, ensuring the plan meets the following conditions:</p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 mt-16">
          <div className={containerStyle}>
            <div className={iconContainer}>
              <GatsbyImage
                image={iconOne}
                alt="icon"
              />
            </div>
            <h2 className={h2Style}>{doc.data.first_card_title}</h2>
            <p className={pStyle}>{doc.data.first_card_text}</p>
          </div>

          <div className={containerStyle}>
            <div className={iconContainer}>
              <GatsbyImage
                image={iconTwo}
                alt="icon"
              />
            </div>
            <h2 className={h2Style}>{doc.data.second_card_title}</h2>
            <p className={pStyle}>{doc.data.second_card_text}</p>
          </div>

          <div className={containerStyle}>
            <div className={iconContainer}>
              <GatsbyImage
                image={iconThree}
                alt="icon"
              />
            </div>
            <h2 className={h2Style}>{doc.data.third_card_title}</h2>
            <p className={pStyle}>{doc.data.third_card_text}</p>
          </div>

          <div className={containerStyle}>
            <div className={iconContainer}>
              <GatsbyImage
                image={iconFour}
                alt="icon"
              />
            </div>
            <h2 className={h2Style}>{doc.data.fourth_card_title}</h2>
            <p className={pStyle}>{doc.data.fourth_card_text}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionFourPartTest
