import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, StaticImage, getImage, getSrc } from "gatsby-plugin-image"

import LayoutLanding from "../components/layout-landing"
import Seo from "../components/seo"
import HeaderLanding from "../components/header/header-landing"
import HeroSection831bNewLanding from "../components/sections/hero-section-831b-new-landing"
import HeroHomeUpdateNew from "../components/sections/hero-home-update-new"
import HeroSectionCapVsMicroLanding from "../components/sections/hero-section-cap-vs-micro-landing"
import SectionFourPartTest from "../components/sections/section-four-part-test"
import SectionCapVsMicroTakeAction from "../components/sections/section-cap-vs-micro-take-action"

const PageCaptiveVsMicroCaptiveLanding = () => {
  const data = useStaticQuery(graphql`
    query PageCaptiveVsMicroCaptiveLandingQuery {
      imgHero: file(relativePath: { eq: "backgrounds/hero-bg-Image.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
      imgSection: file(relativePath: { eq: "sections/captive-isnurance-section-image.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
        imgSectionAction: file(relativePath: { eq: "backgrounds/take-action-831b-plan.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

const imgHero = getImage(data.imgHero)
const imgSection = getImage(data.imgSection)
const imgSectionAction = getImage(data.imgSectionAction)
const imgSrc = getSrc(data.imgHero)

  return (
    <LayoutLanding>
      <Seo
        title="Captive vs. Micro-Captive"
        description="This high-level introduction will walk you through the key distinctions between captive and micro-captive insurance companies and help you decide which approach is best for your business."
        image={imgSrc}
        noIndex
      />

      <HeaderLanding />

      <HeroSectionCapVsMicroLanding
        topTitle="Captive Insurance Company"
        bottomTitle="Micro-Captive Insurance Company"
        imgSrc={imgHero}
      />

      <div>
        <div className="red-bubble-section w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-6 px-6 md:px-16 -mt-8 md:-mt-16 z-20 rounded-3xl shadow-lg">
          <h2 className="text-white text-center text-2xl md:text-3xl font-semibold mt-0 mb-6">What are the differences, and why should you care?</h2>

          <p className="text-white text-center font-medium text-lg md:text-xl mb-6">In today's competitive business environment, proactive business owners face the challenge of protecting their companies from various risks, both known and unknown. Rising commercial insurance premiums can pose a significant financial burden, but there’s a proven solution many companies have used for 38 years—captive insurance.</p>

          <p className="text-white text-center font-medium text-lg md:text-xl mb-6">This high-level introduction will walk you through the key distinctions between captive and micro-captive insurance companies and help you decide which approach is best for your business.</p>
        </div>
      </div>

      <div className="bg-white">
        <div className="relative w-full pt-8 pb-16 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex flex-col lg:flex-row items-center justify-center mx-auto lg:space-x-16 p-2'>
            <div className="w-full lg:w-2/3">
              <div className="z-20 flex flex-col items-start justify-start">                
                <h2 className="text-site-red text-5xl text-center lg:text-left font-extrabold uppercase tracking-wider mb-8">The Birth of Captive Insurance</h2>
                <p className='text-site-gray-dark text-2xl text-center lg:text-left mb-6'>In 1986, as the U.S. economy emerged from a recession, Congress introduced a provision within the tax code, rooted in practices dating back to 1919 when General Motors established a wholly-owned insurance entity. This provision became what is now known as captive insurance, enabling businesses to reduce commercial insurance premiums and underwrite unique risks. Today, businesses can choose between two types of parent-company-owned insurance entities:</p>
                <ol className="list-decimal list-inside ml-4">
                  <li className="text-site-gray-dark text-2xl font-bold mb-2">831(a) Captive Insurance Company</li>
                  <li className="text-site-gray-dark text-2xl font-bold mb-2">831(b) Micro-Captive Insurance Company</li>
                </ol>
              </div>
            </div>
            <div className="w-full lg:w-1/3 flex flex-col items-center justify-center mt-8 lg:mt-0">
              <GatsbyImage
                alt="section image"
                image={imgSection}
                formats={["auto", "webp", "avif"]}
                className="z-20"
              />              
            </div>
          </div>

          <div className="absolute bottom-0 right-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
              width={900}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div>

      <div id="featured-podcasts" className="bg-site-gray-new pt-16 md:pt-20 pb-8 md:pb-16 z-10">
        <div className="w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">
          <h2 className='text-gray-100 text-lg md:text-3xl text-center uppercase mb-2 tracking-widest'>Key Differences Between</h2>
          <h2 className="text-white text-2xl md:text-5xl text-center font-extrabold tracking-wider">831(a) AND 831(b)</h2>
        </div>

        <div className="w-11/12 max-w-screen-lg mt-8 mb-4 mx-auto">
          <table class="table-auto w-full border-separate border-spacing-4 border border-slate-500 border-site-gray-new ...">
            <thead>
              <tr>
                <th class="font-body border rounded-lg bg-site-red text-white border-site-red text-xl lg:text-2xl py-2">Topic</th>
                <th class="font-body border rounded-lg bg-site-red text-white border-site-red text-xl lg:text-2xl py-2">831(a)</th>
                <th class="font-body border rounded-lg bg-site-red text-white border-site-red text-xl lg:text-2xl py-2">831(b)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">Federal Income Tax</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">Taxed at regular corporate rates</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">0% federal tax on underwriting profits</td>
              </tr>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">Risk Coverage</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">Designed for large corporations</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark pl-2">Best for small to mid-sized businesses</td>
              </tr>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">Regulatory Scrutiny</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">Lower scrutiny</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">Higher scrutiny</td>
              </tr>
              <tr>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-red py-2 font-bold pl-2">Premium Limits</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">No specific limit</td>
                <td class="font-body border border-white bg-white text-md lg:text-lg text-site-gray-dark py-2 pl-2">$2.8 million in 2024 (with an inflation rider)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-site-green-light pt-16 md:pt-32 pb-12 md:pb-24">
        <div className="relative flex flex-col items-center">
          <div className="w-11/12 md:w-auto absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto z-20">
            <h2 className="text-white text-base md:text-2xl font-bold text-center">
              WHEN 831(a) CAPTIVE INSURANCE MAKES SENSE
            </h2>
          </div>
          
          <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white border-2 border-site-gray-dark mx-auto p-8 rounded-3xl shadow-lg overflow-hidden z-10">
            <div className="green-bubble-slice-section w-full max-w-2xl flex flex-col justify-center mt-12 z-10">
              <p className="text-base md:text-xl mb-6">A traditional 831(a) captive insurance company is typically a good fit for large corporations with extensive global operations. These companies face more significant risks and often seek ways to reduce the premiums they pay to third-party insurers. A single-parent captive is owned by one large company, while group captives, often formed by industry associations, insure the collective risks of member companies.</p>

              <h3 className="text-site-red text-xl md:text-2xl font-extrabold mt-6 mb-6">However, 831(a) may not be the best option if:</h3>

              <ul className="prismic-ul-class">
                <li className="prismic-li-class"><b>Your company has insufficient profits to pay premiums consistently.</b></li>
                <li className="prismic-li-class"><b>The savings in premiums don’t offset in-house management costs.</b></li>
                <li className="prismic-li-class"><b>You lack the financial resources or personnel to manage the captive entity.</b></li>
              </ul>
            </div>
            <div className="absolute bottom-0 -mb-2 opacity-20 z-0">
              <StaticImage
                src="../../images/backgrounds/sra-mtn.png"
                width={600}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="background image"
              />
            </div>
          </div>
        </div>

        <div className="relative flex flex-col items-center mt-24">
          <div className="w-11/12 md:w-auto absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto z-20">
            <h2 className="text-white text-base md:text-2xl font-bold text-center">
              THE 831(b) MICRO-CAPTIVE INSURANCE PLAN
            </h2>
          </div>
          
          <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white border-2 border-site-gray-dark mx-auto p-8 rounded-3xl shadow-lg overflow-hidden z-10">
            <div className="green-bubble-slice-section w-full max-w-2xl flex flex-col justify-center mt-12 z-10">
              <p className="text-base md:text-xl mb-6">For small to mid-sized businesses, an 831(b) micro-captive insurance plan offers substantial risk mitigation benefits, with the added advantage of tax deferral.</p>

              <h3 className="text-site-red text-xl md:text-2xl font-extrabold mt-6 mb-6">Here’s what you need to know:</h3>

              <ul className="prismic-ul-class">
                <li className="prismic-li-class"><b>Business Type</b>: Closely-held, private businesses in nearly every industry can benefit.</li>
                <li className="prismic-li-class"><b>Gross Revenues</b>: Your business should have annual revenues of at least $1 million.</li>
                <li className="prismic-li-class"><b>Gross Premium Income</b>: The 831(b) plan allows for up to $2.85 million in premiums annually (2024), with an inflation adjustment.</li>
              </ul>
            </div>
            <div className="absolute bottom-0 -mb-2 opacity-20 z-0">
              <StaticImage
                src="../../images/backgrounds/sra-mtn.png"
                width={600}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="background image"
              />
            </div>
          </div>
        </div>

        <div className="relative flex flex-col items-center mt-24">
          <div className="w-11/12 md:w-auto absolute top-0 bg-site-green py-4 px-6 md:px-16 rounded-full -mt-8 mx-auto z-20">
            <h2 className="text-white text-base md:text-2xl font-bold text-center">
              WHAT THE 831(b) PLAN IS NOT
            </h2>
          </div>
          
          <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center bg-white border-2 border-site-gray-dark mx-auto p-8 rounded-3xl shadow-lg overflow-hidden z-10">
            <div className="green-bubble-slice-section w-full max-w-2xl flex flex-col justify-center mt-12 z-10">
              <p className="text-base md:text-xl mb-6">While the 831(b) plan offers notable advantages, it’s essential to understand its purpose:</p>

              <ul className="prismic-ul-class">
                <li className="prismic-li-class"><b>It is not a strategy for lowering taxes.</b></li>
                <li className="prismic-li-class"><b>It is not an unrestricted access point for cash reserves.</b></li>
              </ul>

              <p className="text-base md:text-xl mb-6">Like any other insurance company, an 831(b) micro-captive operates for profit by processing claims with substantiating paperwork and procedures.</p>
            </div>
            <div className="absolute bottom-0 -mb-2 opacity-20 z-0">
              <StaticImage
                src="../../images/backgrounds/sra-mtn.png"
                width={600}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="background image"
              />
            </div>
          </div>
        </div>        
      </div>

      <SectionFourPartTest />

      <SectionCapVsMicroTakeAction 
        topTitle="Take Action"
        bottomTitle="Protect Your Business with an 831(b) Plan"
        imgSrc={imgSectionAction}
      />
    </LayoutLanding>
  )
}

export default PageCaptiveVsMicroCaptiveLanding
