import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  display: grid;
  background: #333333D4;
  min-height: 400px;
`
const StyledDiv2 = styled.div`
  grid-area: 1/1;
  position: relative;
  place-items: center;
  display: grid;

  @media (max-width: 767px) {
    place-items: center;
  }

  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  opacity: 0.7;
  width: 100%;
  height: 500px;

  @media (max-width: 767px) {
    height: 600px;
  }
`

const SectionCapVsMicroTakeAction = ({ imgSrc, topTitle, bottomTitle }) => {
  const data = useStaticQuery(graphql`
    query SectionCapVsMicroTakeActionQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <StyledDiv className="relative">
        <StyledGatsbyImage
          alt="hero image"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <StyledDiv2>
          <div className="w-11/12 lg:w-10/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">
            <div className="w-full flex flex-col items-center justify-center">
              <h2 className="text-white text-3xl lg:text-5xl font-extrabold text-center text-shadow uppercase">
                {topTitle ? topTitle : "Top Title"}
              </h2>

              <h2 className="text-white text-3xl lg:text-5xl font-extrabold text-center text-shadow mt-4">
                {bottomTitle ? bottomTitle : "Bottom Title"}
              </h2>

              <div className='mt-12 mb-0'>
                <div className="hidden xl:flex flex-col items-start justify-start">
                  <Link to="/qualify">
                    <button id="qualify-button" className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3">
                        <span className="default-text mx-2">Do You Qualify?</span>
                        <span className="hover-text">Begin Assessment</span>
                      </button>
                  </Link>
                </div>
                
                <div className="xl:hidden flex flex-col items-center justify-center">                    
                  <div className="mb-4">
                    <p className="text-white text-shadow text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                      <Link to="/qualify">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                        Begin Assessment
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledDiv2>
      </StyledDiv>
    </>
  )
}

export default SectionCapVsMicroTakeAction
