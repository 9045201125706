import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  display: grid;
  background: #333333D4;
  min-height: 400px;
`
const StyledDiv2 = styled.div`
  grid-area: 1/1;
  position: relative;
  place-items: center;
  display: grid;

  @media (max-width: 767px) {
    place-items: center;
  }

  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  opacity: 0.7;
  width: 100%;
  height: 500px;

  @media (max-width: 767px) {
    height: 600px;
  }
`

const HeroSectionCapVsMicroLanding = ({ imgSrc, topTitle, bottomTitle }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionCapVsMicroLandingQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <StyledDiv className="relative pt-14 xl:pt-20 -z-10">
        <StyledGatsbyImage
          alt="hero image"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <StyledDiv2>
          <div className="w-11/12 lg:w-10/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">
            <div className="w-full flex flex-col items-center justify-center">
              <h2 className="text-white text-4xl lg:text-6xl font-extrabold text-center text-shadow">
                {topTitle ? topTitle : "Top Title"}
              </h2>

              <span className="text-site-red text-4xl lg:text-6xl font-extrabold text-center text-shadow mt-4">vs.</span>

              <h2 className="text-white text-4xl lg:text-6xl font-extrabold text-center text-shadow mt-4">
                {bottomTitle ? bottomTitle : "Bottom Title"}
              </h2>

              
            </div>
          </div>
        </StyledDiv2>
      </StyledDiv>
    </>
  )
}

export default HeroSectionCapVsMicroLanding
